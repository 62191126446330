import { Box, Container, Flex, Heading, HStack, Text } from "@chakra-ui/react"
import { memo, useMemo } from "react"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import { ListingHeader } from "@components/Listing/ListingHeader"
import { SearchFilterTopCollection } from "@components/Search/Filters/SearchFilterTopCollection"
import { SearchSort } from "@components/Search/Sort/SearchSort"
import { SearchResults } from "@components/Search/Results/SearchResults"
import { CollectionContent } from "@components/Collection/CollectionContent"
import { usePageCollection } from "@app/hooks/usePageCollection"
import { useMedia } from "@app/hooks/useMedia"
import { useImage } from "@app/hooks/useImage"
import { useCore } from "@app/hooks/useCore"

type Props = {
  page: any
  collection: any
}

const ReviewsCollection: React.FC<Props> = ({ page, collection }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { isMedium } = useMedia()
  const { getGatsbyImage } = useImage()
  const { getPageCollection } = usePageCollection({ collection })
  const { item, shopifyPermanentDomain, headerContent, bottomContent } = getPageCollection()
  const collectionPageBanner = useMemo(
    () => (isMedium ? getGatsbyImage(page?.collectionPageBanner?.desktop) : getGatsbyImage(page?.collectionPageBanner?.mobile)),
    [getGatsbyImage, isMedium, page?.collectionPageBanner?.desktop, page?.collectionPageBanner?.mobile]
  )

  return isBrowser ? (
    <ReactifySearchProvider
      mode="collection"
      collectionHandle={collection?.shopify?.handle}
      shopifyPermanentDomain={shopifyPermanentDomain}
    >
      <Sensors />
      <ListingHeader title={item?.title}>
        <Text maxW="970px" mb={4} lineHeight="21px" fontSize="sm" dangerouslySetInnerHTML={{ __html: headerContent }} />
        <SearchFilterTopCollection topCollectionHandle={collection?.headerFilter} />
      </ListingHeader>
      <Box>
        <Container as={HStack} alignItems="stretch" spacing={[0, 0, 4]}>
          <Box py={4} pl={[0, 0, 4]} bg="brand.offWhite" flex={1} maxW="full">
            <Flex
              flexDirection="row"
              as="header"
              h={["452px", null, "220px"]}
              mt={10}
              mb="30px"
              backgroundImage={`url(${collectionPageBanner?.src})`}
              backgroundSize="cover"
              backgroundPosition="center"
            >
              <Container>
                <Flex h="100%" pl={[0, "18px", "20%"]} pr={[0, "18px"]} pb="15px" alignItems={["flex-end", null, "flex-start"]}>
                  <Heading
                    as="h1"
                    size={["xl", "3xl", "h3"]}
                    lineHeight="1.3 !important"
                    maxW="605px"
                    mx={["auto", null, 0]}
                    mt="27px"
                    textAlign={["center", null, "left"]}
                  >
                    How was your overall
                    {isMedium ? <br /> : <>&nbsp;</>}
                    GoodnessMe Box Experience?
                  </Heading>
                </Flex>
              </Container>
            </Flex>
            <SearchSort topCollectionHandle={collection?.headerFilter} />
            <SearchResults isReviewsPage={true} />
            <CollectionContent content={bottomContent} />
          </Box>
        </Container>
      </Box>
    </ReactifySearchProvider>
  ) : null
}

export default memo(ReviewsCollection)
