import { Box, Button, Container, Flex, Grid, Heading, Image, Text } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { memo, useMemo } from "react"
import { useImage } from "@app/hooks/useImage"
import { useMedia } from "@app/hooks/useMedia"
import { CustomLink } from "../Link"

type Props = {
  page: any
}

const ReviewsLanding: React.FC<Props> = ({ page }) => {
  const { isMedium } = useMedia()
  const { getGatsbyImage } = useImage()
  const { getContent } = useContent({ type: page?._type })
  const content = getContent({ content: page?.content })
  const responsiveImg = useMemo(
    () => (isMedium ? getGatsbyImage(page?.landingPageBanner?.desktop) : getGatsbyImage(page?.landingPageBanner?.mobile)),
    [getGatsbyImage, isMedium, page?.landingPageBanner?.desktop, page?.landingPageBanner?.mobile]
  )

  return (
    <Box as="section" pt={[16, 20]} pb={[10, 18]}>
      <Flex
        flexDirection="row"
        as="header"
        bg="brand.lavender"
        mt="-80px"
        h={["704px", null, "460px"]}
        backgroundImage={`url(${responsiveImg?.src})`}
        backgroundSize="cover"
        backgroundPosition={["center", null, "right center"]}
      >
        <Container>
          <Flex justifyContent="center" alignItems={["flex-end", null, "center"]} w={["100%", null, "522px"]} h="100%">
            <Heading as="h1" size={["4xl", null, "h2"]} lineHeight={1.2} mb={[12, null, 16]} textAlign={["center", null, "left"]}>
              Were all ears! Tell us what you think of your box.
            </Heading>
          </Flex>
        </Container>
      </Flex>
      <Box mb={[10, 12]} lineHeight="24px">
        {content}
      </Box>
      <Container variant="example">
        <Heading as="h2" size={["3xl", null, "h2"]} mb={[8, 16]} textAlign="center">
          Select your box to review
        </Heading>
        <Box mb={[10, 12]} lineHeight="24px">
          <Grid
            flexDirection={{ base: "column", lg: "row" }}
            gridTemplateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
            gridColumnGap={4}
            gridRowGap={["56px", 12]}
          >
            {page?.reviewcollections?.map((item: any) => (
              <CustomLink
                key={item?.id}
                to={`/reviews/${item?.shopify?.handle}`}
                styles={{
                  maxW: "full",
                  w: "full",
                  display: "flex",
                  justifyContent: "start",
                  flexDir: "column",
                  textAlign: "left",
                  bg: ["brand.offWhite" || "brand.offWhite", "inherit"],
                  borderRadius: ["base", "base", "none"],
                  m: { base: "0 0", lg: "0 20px" },
                }}
              >
                <Flex bg="brand.inputBg" justifyContent="center" w="full" borderRadius={["base", "base", "none"]}>
                  <Image src={item?.image} h="100%" />
                </Flex>
                <Heading as="h4" size={["xl", "h4"]} textAlign="left" my={[4, 2]}>
                  {item?.title}
                </Heading>
                <Text as="p" size={["md", "p"]}>
                  Tell us what you think...
                </Text>
                <Button w={["100%", null, "50%"]} size="lg" mt={[4, 4]}>
                  Review Now
                </Button>
              </CustomLink>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default memo(ReviewsLanding)
