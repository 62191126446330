import { memo, useMemo } from "react"

import type { Props } from "@app/pages/contact"
import type { PageProps } from "@root/types/global"
import ReviewsLanding from "@components/Reviews/ReviewsLanding"
import ReviewsCollection from "@components/Reviews/ReviewsCollection"

const Reviews: React.FC<PageProps<Props>> = ({ page, location }) => {
  const collectionHandle = location?.pathname?.split("/reviews/")[1] || null
  const collection = useMemo(
    () => page?.reviewcollections?.find((collection: any) => collection?.shopify?.handle === collectionHandle),
    [collectionHandle, page?.reviewcollections]
  )

  return collection ? <ReviewsCollection page={page} collection={collection} /> : <ReviewsLanding page={page} />
}

export default memo(Reviews)
