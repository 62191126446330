import { graphql, PageProps } from "gatsby"
import Page from "@components/Reviews/Reviews"

export type Props = PageProps<GatsbyTypes.PageReviewsQuery, GatsbyTypes.PageReviewsQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageReviews {
    page: sanityPageReviews {
      _type
      title
      url
      landingPageBanner {
        desktop: _rawDesktop(resolveReferences: { maxDepth: 2 })
        mobile: _rawMobile(resolveReferences: { maxDepth: 2 })
      }
      collectionPageBanner {
        desktop: _rawDesktop(resolveReferences: { maxDepth: 2 })
        mobile: _rawMobile(resolveReferences: { maxDepth: 2 })
      }
      content: _rawContent(resolveReferences: { maxDepth: 5 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      reviewcollections {
        title
        url
        image
        shopify {
          handle
        }
        id
        headerFilter
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
